<template>
	<div class="tab tab-btns">
		<template v-for="item in items">
			<router-link tag="a" class="tab-btn btn" :to="item.route" v-if="!item.isHidden" :key="`key-${item.name}`"
						 :class="{'btn-primary': activeClass(item.name), 'btn-light': !activeClass(item.name)}">
				<template v-if="item.icon">
					<span>{{item.icon}}</span>
				</template>{{item.title}}
			</router-link>
		</template>
	</div>

</template>

<script>
    export default {
        name: "Tab.vue",
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        methods: {
            activeClass: function (name) {
                return this.$route.name.includes(name);
            }
        }
    }
</script>

<style scoped>
	.circle-block {
		font-size: 14px;
		line-height: 12px;
		width: 25px;
		height: 25px;
		border-radius: 15px;
		text-align: center;
		display: inline-block;
		padding: 4px;
		border: 2px solid;
	}


</style>
<style lang="scss">
	@import "../assets/styles/blocks/tabs";
</style>
